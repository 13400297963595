<template>
  <div class="notifyBox">
    <!-- title -->
    <!-- <h1>
      <img src="@/assets/newImage/service/helpIcon.png" alt="" />
      <span>Notify</span>
    </h1> -->
    <!-- tan Box -->
    <div class="tabBox">
      <div @click="changeTab(1)">
        Calculator
      </div>
      <div class="active" @click="changeTab(2)">
        Notify
      </div>
    </div>
    <!-- ipt btn -->
    <div class="searchBox">
      <el-input
        class="numIpt"
        @input="changeIpt"
        maxlength="12"
        v-model="phoneNum"
        clearable
      ></el-input>
      <el-button
        :disabled="phoneNum ? false : true"
        :style="{ background: phoneNum ? '#2ea76a' : '#b3b3b3' }"
        class="verifyBtn"
        @click="verifyBtn"
        >Inquiry number</el-button
      >
    </div>
    <!-- title Box -->
    <div class="titleStatus">
      <div v-show="verifyStatus == 1">
        <img src="@/assets/image/service/success.png" alt="" />
        <span style="color: #2ea76a"> Officially verified number </span>
      </div>
      <div v-show="verifyStatus == 0">
        <img src="@/assets/image/service/error.png" alt="" />
        <span style="color: #e73400"> Unofficially Verified Number </span>
      </div>
    </div>
    <!-- all content -->
    <div class="allContentBox">
      <!-- phone List -->
      <div class="phoneList">
        <p>
          <img src="@/assets/newImage/service/phoneIcon.png" alt="" />
          Official Phone Number
        </p>
        <div class="phoneBox">
          <span>0516011632 - 0516011932</span>
          <span
            >051-111-883-883 &nbsp;&nbsp; 051-884-127-0 &nbsp;&nbsp;
            0315-3003-873</span
          >
        </div>
        <div class="phoneBox">
          <span>0518700040 - 0518700041</span>
          <span>051-8700-014</span>
          <span>051-8700-037</span>
        </div>
        <div class="phoneBox">
          <span>0518841270 - 0518841289</span>
          <span>310-8153-944</span>
        </div>
        <div class="phoneBox">
          <span>0518700336 - 0518700340</span>
          <span>051-8700-097</span>
        </div>
        <div class="phoneBox">
          <span>0518700582 - 0518700618</span>
          <span>051-8700-099</span>
        </div>
        <div class="phoneBox">
          <span>0518700818 - 0518700840</span>
          <span>051-8700-126</span>
        </div>
        <div class="phoneBox">
          <span>0518700849 - 0518700872</span>
          <span>051-8700-135</span>
        </div>
        <div class="phoneBox">
          <span>0518700874 - 0518700877</span>
          <span>051-8700-436</span>
        </div>
        <div class="phoneBox">
          <span>03174997671 - 03174997672</span>
          <span>031-7499-6981</span>
          <span>034-0689-9921</span>
        </div>
        <div class="phoneBox">
          <span>03178223672 - 03178223676</span>
          <span>034-0689-9982</span>
          <span>034-0689-9897</span>
        </div>
      </div>

      <!-- content text -->
      <div class="contentText">
        <h2>Under Licensed Operating Institution</h2>
        <p>
          Paisayaar is an online loan application registered with SECP and
          certified with NBFC, through which you can obtain secure and flexible
          loans. All loan expenses are openly transparent, with no hidden
          charges.
        </p>
        <h2>Data Privacy Protection</h2>
        <p>
          When applying for a loan on Paisayaar, you need to provide some of
          your personal information. We assure you that the collected
          information is solely for the purpose of granting you the loan. The
          scope of collected information is limited to the necessary details
          required to provide you with the loan. Your information will not be
          disclosed or used for any other purposes.
        </p>
        <h2>
          Special reminder, please pay attention to the following situations
        </h2>
        <div>
          Our repayment methods will be displayed within the official Paisayaar
          App and the official website. Currently, we only support repayment
          through Easypaisa and JazzCash. When making repayments, please ensure
          to follow the method provided by the company:
        </div>
        <div>
          · Please ensure to obtain the repayment code through the Paisayaar
          App. When making the repayment, search for [Paisayaar] on Easypaisa
          and JazzCash. You will see our application. Enter the repayment code
          to complete the process.
        </div>
        <div>
          · Please ensure to verify the recipient account as [Paisayaar] during
          the repayment process. Kindly avoid transferring payments to
          unfamiliar accounts!
        </div>
        <div style="margin-bottom: 70px;">
          · If someone contacts you and provides a personal repayment account,
          please do not trust or provide any information. If you encounter such
          a situation, please promptly report it to us. If your feedback is
          genuine and effective, we will reward you with a certain additional
          incentive.
        </div>

        <h2>
          How to cancel my account?
        </h2>
        <div>
          Enter the app, click [My Account] - click [Settings] - click [Account
          and Security] - click [Account Cancellation], and follow the prompts.
          Or contact customer service 051-111-883-883 or 051-884-127-0 for
          review. After the customer service review is passed, your account will
          be cancle within 5 working days.
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  data() {
    return {
      phoneNum: "", // 电话号码
      verifyStatus: 2, // 判断状态
    };
  },
  watch: {
    phoneNum: function() {
      if (!this.phoneNum) {
        this.verifyStatus = 2;
      }
    },
  },
  methods: {
    // 判断电话
    verifyBtn() {
      let phoneNumber = Number(this.phoneNum.slice(7));
      console.log("[ phoneNumber ] >", phoneNumber);
      if (this.phoneNum.length == 10) {
        if (
          (this.phoneNum.includes("0516011") &&
            Number(this.phoneNum.slice(7) >= 632) &&
            Number(this.phoneNum.slice(7) <= 932)) ||
          (this.phoneNum.includes("0518841") &&
            Number(this.phoneNum.slice(7) >= 270) &&
            Number(this.phoneNum.slice(7) <= 289)) ||
          (this.phoneNum.includes("0516000") &&
            Number(this.phoneNum.slice(7) >= 480) &&
            Number(this.phoneNum.slice(7) <= 654)) ||
          (phoneNumber >= 336 && phoneNumber <= 340) ||
          (phoneNumber >= 582 && phoneNumber <= 618) ||
          (phoneNumber >= 818 && phoneNumber <= 840) ||
          (phoneNumber >= 849 && phoneNumber <= 872) ||
          (phoneNumber >= 874 && phoneNumber <= 877) ||
          phoneNumber == 14 ||
          phoneNumber == 97 ||
          phoneNumber == 99 ||
          phoneNumber == 126 ||
          phoneNumber == 135 ||
          phoneNumber == 436 ||
          phoneNumber == 37
        ) {
          this.verifyStatus = 1;
        } else {
          this.verifyStatus = 0;
        }
      } else if (
        this.phoneNum.length == 11 &&
        this.phoneNum.includes("03205690") &&
        this.phoneNum.slice(8) >= 243 &&
        this.phoneNum.slice(8) <= 272
      ) {
        this.verifyStatus = 1;
      } else if (
        this.phoneNum.includes("03174997") &&
        this.phoneNum.slice(8) >= 671 &&
        this.phoneNum.slice(8) <= 672
      ) {
        this.verifyStatus = 1;
      } else if (
        this.phoneNum.includes("031782236") &&
        this.phoneNum.slice(8) >= 72 &&
        this.phoneNum.slice(8) <= 76
      ) {
        this.verifyStatus = 1;
      } else if (
        this.phoneNum.length == 11 &&
        this.phoneNum.slice(9) >= 40 &&
        this.phoneNum.slice(9) <= 41
      ) {
        this.verifyStatus = 1;
      } else if (
        this.phoneNum == "051111883883" ||
        this.phoneNum == "0518841270" ||
        this.phoneNum == "03088889823" ||
        this.phoneNum == "03153003873" ||
        this.phoneNum == "03174996981" ||
        this.phoneNum == "03406899921" ||
        this.phoneNum == "03406899982" ||
        this.phoneNum == "03406899897"
      ) {
        this.verifyStatus = 1;
      } else {
        this.verifyStatus = 0;
      }
      this.$sensors.track("c_notify_button", {
        // 传递的参数
        source_page: "点击查询号码", // 点击的页面
      });
    },
    // 限制输入数字
    changeIpt(val) {
      this.phoneNum = val.replace(/\D/g, "");
    },
    changeTab(row) {
      this.$emit("changeTab", row);
    },
  },
};
</script>

<style lang="scss" scoped>
.notifyBox {
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 1440px;
  // height: 1390px;
  background: linear-gradient(180deg, #ffffff 0%, #ffffff 100%);
  box-shadow: 0px 0px 20px 0px #eaecef,
    inset 0px 2px 0px 0px rgba(255, 255, 255, 0.5);
  border-radius: 40px;
  margin: 88px auto 0;
  padding-bottom: 120px;
  h1 {
    display: flex;
    align-items: center;
    justify-content: center;
    font-size: 36px;
    font-family: OpenSans-Bold, OpenSans;
    font-weight: bold;
    color: #2c2c34;
    img {
      margin-right: 16px;
      width: 54px;
      height: 54px;
    }
  }
  .searchBox {
    display: flex;
    justify-content: space-between;
    width: 800px;
    margin: 47px auto 16px;
    .numIpt {
      width: 582px;
      height: 68px;
      background: #ffffff;
      border-radius: 10px;
      border: 2px solid #999999;
      /deep/ .el-input__inner {
        height: 100%;
        border: none;
        border-radius: 10px;
        font-size: 20px;
      }
      /deep/ .el-input__icon {
        font-size: 20px;
      }
    }
    .verifyBtn {
      width: 196px;
      height: 68px;
      // background: #b3b3b3;
      background-color: #2ea76a;
      border-radius: 10px;
      font-size: 22px;
      font-family: PingFangSC-Regular, PingFang SC;
      font-weight: 400;
      color: #ffffff;
      padding: 0;
      border: none;
    }
  }

  .titleStatus {
    width: 800px;
    // height: 50px;
    margin: 14px 0 40px;
    font-size: 24px;
    img {
      width: 32px;
      height: 32px;
      margin-right: 16px;
    }
    div {
      display: flex;
      align-items: center;
    }
  }

  .allContentBox {
    width: 800px;
    .phoneList {
      margin-bottom: 50px;
      p {
        display: flex;
        align-items: center;
        font-size: 22px;
        font-family: OpenSans-Regular, OpenSans;
        font-weight: 400;
        color: #333333;
        margin-bottom: 16px;
        img {
          width: 18px;
          height: 18px;
          margin-right: 7px;
        }
      }
      .phoneBox {
        font-size: 18px;
        font-family: OpenSans-Regular, OpenSans;
        font-weight: 400;
        color: #999999;
        margin-bottom: 11px;
        span {
          margin-right: 56px;
        }
      }
    }
    .contentText {
      h2 {
        font-size: 22px;
        color: #333;
        font-weight: 700;
        margin-bottom: 20px;
      }
      p,
      div {
        font-size: 18px;
        color: #999;
        font-weight: 400;
        margin-bottom: 70px;
      }

      div {
        margin-bottom: 20px;
      }
    }
  }
}

.tabBox {
  display: flex;
  width: 1240px;
  height: 56px;
  background: #f5f6f9;
  border-radius: 28px;
  overflow: hidden;
  margin: 63px auto 72px;
  & > div {
    cursor: pointer;
    width: 50%;
    text-align: center;
    font-size: 22px;
    font-family: OpenSans-Regular, OpenSans;
    font-weight: 400;
    color: #333333;
    line-height: 56px;
  }
  .active {
    background: #2ea76a;
    border-radius: 28px;
    font-size: 22px;
    font-family: OpenSans-Bold, OpenSans;
    font-weight: bold;
    color: #fff;
    border-radius: 28px;
  }
}
</style>
